<template>
  <div>
    <div class="h-full" v-if="isFilterOpen">
      <div class="bg-white">
        <div class=" bg-primary2 p-2 items-center justify-between flex h-11 sticky top-0">
          <div class="heading-4 text-white font-bold">Filter</div>
          <div class=" text-white cursor-pointer hover:underline" @click="applyFilter()">
            <button style="letter-spacing: 1px;" class="border border-gray1 px-3 py-1 cust_card heading-7">Apply</button>
            <!-- <i class="h-4 w-4 text-white fas fa-refresh"></i> -->
            <!-- <span class=""><img class="w-6" src="@/assets/images/svg/filter.svg"></span> -->
          </div>
        </div> 
        <div class="pt-1">
          <div v-if="filterObject.startDate !== '' || filterObject.endDate !== ''">
            <p class="text-text1 heading-6 font-bold pl-2">
              <span class="text-text2 heading-6 font-bold">{{filterObject.startDate | DMMYYYYddd}}</span> 
              To
              <span class="text-text2 heading-6 font-bold">{{filterObject.endDate | DMMYYYYddd}}</span>
            </p>
          </div>
          <div class="pt-1 pl-1">
            <button style="letter-spacing: 1px"
            v-for="(data, index) in buttonFiltersArray" :key="index"
              @click="getDateTimeForFilterHandler(index, true)"
              :class="data.selected ? 'text-white bg-primary2' : 'text-primary2 bg-white hover:bg-blue-50'"
              class="m-1 px-1 py-0.5 heading-7 rounded-md border border-primary2"
              >{{data.title}}</button
            >
          </div>
        </div>
      </div>
    </div>
    <div class="bg-white container" v-if="showCustomDatepicker">
      <div class="popup_overlay">
        <CustomDateSelector :appliedFilters="filterObject" @closeSelectedDialog="customDateListener" v-if="showCustomDatepicker"/>
      </div>
    </div>
  </div>
</template>
<script>
import API from '@/api/App.js'
import CustomDateSelector from '@/View/components/customDateSelection'
import {getDayAccordingToLimits} from '@/utils/convertDateAndTime.js'
// import Pagination from '@/View/components/pagination.vue'
export default {
  props: ['callFrom', 'callParent', 'isFilterOpen'],
  components: {
    CustomDateSelector,
  },
  data () {
    return {
      drag: false,
      deleteConfirm: false,
      isRefresh: true,
      listingArray: [],
      selectAllCheck: false,
      parentListDisplay: [],
      requiredDataListing: [],
      buttonFiltersArray: [
        {
          title: 'Today',
          selected: false,
          value: 'today'
        },
        {
          title: 'Yesterday',
          selected: false,
          value: 'yesterday'
        },
        {
          title: 'Last Week',
          selected: false,
          value: 'lastWeek'
        },
        {
          title: 'Last 7 Days',
          selected: false,
          value: 'thisWeek'
        },
        {
          title: 'Last Month',
          selected: false,
          value: 'lastMonth'
        },
        {
          title: 'Last 30 Days',
          selected: true,
          value: 'last30Days'
        },
        {
          title: 'Last 60 Days',
          selected: false,
          value: 'last60Days'
        },
        {
          title: 'Last 90 Days',
          selected: false,
          value: 'last90Days'
        },
        {
          title: 'Last 120 Days',
          selected: false,
          value: 'last120Days'
        },
        {
          title: 'Custom',
          selected: false,
          value: 'custom'
        }
      ],
      filterObject: {
        startDate: '',
        endDate: '',
        minDate: '',
        maxDate: '',
      },
      selectedValue: 'last30Days',
      defaultStartDate: '',
      defaultEndDate: '',
      showCustomDatepicker: false,
      firstLoading: false,
    }
  },
  created() {
  },
  beforeMount () {
  },
  mounted () {
    console.log('callParent', this.callParent)
    console.log('callFrom', this.callFrom)
    this.defaultEndDate = new Date()
    let currentDate = new Date()
    this.defaultStartDate = new Date(currentDate.setDate(currentDate.getDate() - 29))
    this.filterObject.startDate = this.defaultStartDate
    this.filterObject.endDate = this.defaultEndDate
    this.apiCaller()
  },
  watch: {
    filterObject: {
      handler () {
        console.log('filterObject', this.filterObject)
      },
      deep: true
    },
  },
  methods: {
    customDateListener (data) {
      console.log('datadatadatadatadatadata', data)
      this.showCustomDatepicker = false
      if (data !== null) {
        this.filterObject.startDate = data.startDate
        this.filterObject.endDate = data.endDate
      }
    },
    getDateTimeForFilterHandler (index, showCustomDatepicker) {
      this.selectedValue = this.buttonFiltersArray[index].value
      console.log('showCustomDatepicker', index, showCustomDatepicker)
      for (let i = 0; i < this.buttonFiltersArray.length; i++) {
        if (i !== index) {
          this.buttonFiltersArray[i].selected = false
        } else {
          this.buttonFiltersArray[i].selected = true
        }
      }
      if (this.buttonFiltersArray[index].value !== 'today' && this.buttonFiltersArray[index].value !== 'custom') {
        getDayAccordingToLimits(this.buttonFiltersArray[index].value).then(val => {
          console.log('val123123', val)
          this.filterObject.startDate = val.startDate
          this.filterObject.endDate = val.endDate
          // this.getListing()
        })
      } else {
        let date = new Date(new Date().setHours(0, 0, 0, 0)).toUTCString()
        if (this.buttonFiltersArray[index].value === 'today') {
          this.filterObject.startDate = date
          this.filterObject.endDate = date
          // this.getListing()
        } else {
          this.showCustomDatepicker = showCustomDatepicker
        }
      }
    },
    applyFilter() {
      this.$emit('closeFilter', this.filterObject)
    },
    apiCaller () {
      this.isRefresh = false
      if (this.callFrom === 'no') {
        console.error("CALL FROM VALUE IS NOT ASSIGNED")
        return false
      }
      this.$store.dispatch('SetLoader', {status: true, message: 'Loading ...'})
      if (this.callFrom === 'PerimeterTechnologies.com' && this.callParent === 'accounting') {
        API.GetAccPeriTechnologyReqData(
          response => {
            this.isRefresh = true
            this.requiredDataListing = response.Data === null ? [] : response.Data
            this.filterObject.minDate = this.requiredDataListing.minDate
            this.filterObject.maxDate = this.requiredDataListing.maxDate
            this.$store.dispatch('SetLoader', {status: false, message: 'Loading ...'})
          },
          error => {
            this.resetPaginationValues = false
            this.$store.dispatch('SetLoader', {status: false, message: 'Loading ...'})
            this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
          }
        )
      } else if (this.callFrom === 'DealersPetStop.com' && this.callParent === 'accounting') {
        API.DealersPetStopRequiredData(
          response => {
            this.isRefresh = true
            this.requiredDataListing = response.Data === null ? [] : response.Data
            this.filterObject.minDate = this.requiredDataListing.minDate
            this.filterObject.maxDate = this.requiredDataListing.maxDate
            this.$store.dispatch('SetLoader', {status: false, message: 'Loading ...'})
          },
          error => {
            this.resetPaginationValues = false
            this.$store.dispatch('SetLoader', {status: false, message: 'Loading ...'})
            this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
          }
        )
      } else if (this.callFrom === 'FlexPetz.com' && this.callParent === 'accounting') {
        API.flexpetzAccountingDateRangeApi(
          response => {
            this.isRefresh = true
            this.requiredDataListing = response.Data === null ? [] : response.Data
            this.filterObject.minDate = this.requiredDataListing.minDate
            this.filterObject.maxDate = this.requiredDataListing.maxDate
            this.$store.dispatch('SetLoader', {status: false, message: 'Loading ...'})
          },
          error => {
            this.resetPaginationValues = false
            this.$store.dispatch('SetLoader', {status: false, message: 'Loading ...'})
            this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
          }
        )
      } else if (this.callFrom === 'Amazon' && this.callParent === 'accounting') {
        API.GetAmazonSageListing(
          response => {
            this.isRefresh = true
            this.requiredDataListing = response.Data === null ? [] : response.Data
            this.filterObject.minDate = this.requiredDataListing.minDate
            this.filterObject.maxDate = this.requiredDataListing.maxDate
            this.$store.dispatch('SetLoader', {status: false, message: 'Loading ...'})
          },
          error => {
            this.resetPaginationValues = false
            this.$store.dispatch('SetLoader', {status: false, message: 'Loading ...'})
            this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
          }
        )
      } else if (this.callFrom === 'Walmart' && this.callParent === 'accounting') {
        API.WalmartRequiredData(
          response => {
            this.isRefresh = true
            this.requiredDataListing = response.Data === null ? [] : response.Data
            this.filterObject.minDate = this.requiredDataListing.minDate
            this.filterObject.maxDate = this.requiredDataListing.maxDate
            this.$store.dispatch('SetLoader', {status: false, message: 'Loading ...'})
          },
          error => {
            this.resetPaginationValues = false
            this.$store.dispatch('SetLoader', {status: false, message: 'Loading ...'})
            this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
          }
        )
      } else if (this.callFrom === 'Shop.PetStop.com' && this.callParent === 'accounting') {
        API.shopPetStopRequiredData(
          response => {
            this.isRefresh = true
            this.requiredDataListing = response.Data === null ? [] : response.Data
            this.filterObject.minDate = this.requiredDataListing.minDate
            this.filterObject.maxDate = this.requiredDataListing.maxDate
            this.$store.dispatch('SetLoader', {status: false, message: 'Loading ...'})
          },
          error => {
            this.resetPaginationValues = false
            this.$store.dispatch('SetLoader', {status: false, message: 'Loading ...'})
            this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
          }
        )
      } else if (this.callFrom === 'ebay' && this.callParent === 'Cost of Goods Sold') {
        API.EbayRequiredData(
          response => {
            this.isRefresh = true
            this.requiredDataListing = response.Data === null ? [] : response.Data
            this.filterObject.minDate = this.requiredDataListing.minDate
            this.filterObject.maxDate = this.requiredDataListing.maxDate
            this.$store.dispatch('SetLoader', {status: false, message: 'Loading ...'})
          },
          error => {
            this.resetPaginationValues = false
            this.$store.dispatch('SetLoader', {status: false, message: 'Loading ...'})
            this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
          }
        )
      } else if (this.callFrom === 'Sage' && this.callParent === 'Cost of goods sold') {
        API.SageDateRequiredData(
          response => {
            this.isRefresh = true
            this.requiredDataListing = response.Data === null ? [] : response.Data
            this.filterObject.minDate = this.requiredDataListing.minDate
            this.filterObject.maxDate = this.requiredDataListing.maxDate
            this.$store.dispatch('SetLoader', {status: false, message: 'Loading ...'})
          },
          error => {
            this.resetPaginationValues = false
            this.$store.dispatch('SetLoader', {status: false, message: 'Loading ...'})
            this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
          }
        )
      } else if (this.callFrom === 'SAGE' && this.callParent === 'accounting') {
        API.AccountingSageDateRangeApi(
          response => {
            this.isRefresh = true
            this.requiredDataListing = response.Data === null ? [] : response.Data
            this.filterObject.minDate = this.requiredDataListing.minDate
            this.filterObject.maxDate = this.requiredDataListing.maxDate
            this.$store.dispatch('SetLoader', {status: false, message: 'Loading ...'})
          },
          error => {
            this.resetPaginationValues = false
            this.$store.dispatch('SetLoader', {status: false, message: 'Loading ...'})
            this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
          }
        )
      } else if (this.callFrom === 'extremeDogDoor' && this.callParent === 'Sales') {
        API.requiredDateData(
          response => {
            this.isRefresh = true
            this.requiredDataListing = response.Data === null ? [] : response.Data
            this.filterObject.minDate = this.requiredDataListing.minDate
            this.filterObject.maxDate = this.requiredDataListing.maxDate
            this.$store.dispatch('SetLoader', {status: false, message: 'Loading ...'})
          },
          error => {
            this.resetPaginationValues = false
            this.$store.dispatch('SetLoader', {status: false, message: 'Loading ...'})
            this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
          }
        )
      }
    },
  }
}
</script>
<style scoped>

.xsmall_col {
  /* min-width: 54px !important; */
  /* width: 54px !important; */
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}
.dates_col {
  /* min-width: 120px !important; */
  /* width: 120px !important; */
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}

.rightText {
  text-align: right !important;
  justify-content: right !important;
}
.total_col {
  /* min-width: 82px !important; */
  /* width: 82px !important; */
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}
.vsmall_col {
  /* min-width: 94px !important; */
  /* width: 94px !important; */
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}
.avgSell_col {
  /* min-width: 133px !important; */
  /* width: 133px !important; */
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}
.avgSell2_col {
  /* min-width: 138px !important; */
  /* width: 138px !important; */
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}

.mid_col {
  /* min-width: 180px !important; */
  /* width: 180px !important; */
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}
.small_col {
  /* min-width: 84px !important; */
  /* width: 84px !important; */
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}
.overFlowParaA {
  word-break: break-all !important;
  white-space: initial !important;
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  display: block;
}

.large_col {
  /* min-width: 260px !important; */
  /* width: 260px !important; */
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}

.desc_col {
  /* min-width: 240px !important; */
  /* width: 240px !important; */
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}

</style>
